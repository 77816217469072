.partners-container {
  background-color: #f6f6e9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  min-height: 50vh;
}

.partners-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  max-width: 90%;
}

/* Image Styling */
.partner-image {
  width: 100%; /* Makes images responsive */
  max-width: 500px; /* Upper limit for larger screens */
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

/* Hover Effect */
.partner-image:hover {
  transform: scale(1.05);
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .partner-image {
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .partners-grid {
    gap: 15px;
  }
  .partner-image {
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .partners-container {
    padding: 20px;
  }
  .partners-grid {
    gap: 10px;
  }
  .partner-image {
    max-width: 90%; /* Allows images to scale down further on small screens */
  }
}
